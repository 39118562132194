import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearVideo,
  setTitle,
  setDescription,
  setPoster,
  addSeries,
  addSelectedSeries,
  editSeries,
  addTrailerProgressBar,
  addTrailerProgressCount,
  setPrompt,
  seetSeed,
  setSeriesSeason,
  setSeriesEpisode,
} from "../../redux/slice/Upload";
import { uploadFileOnS3 } from "../../utils/aws";
import { Copy, Plus, X, LoaderCircle, Trash } from "lucide-react";
import { api } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import mixpanel from "mixpanel-browser";

export default function Details({ groupId }) {
  const {
    videoUrl,
    title,
    description,
    poster,
    series,
    prompt,
    seed,
    selectedSeries,
    noOfEpisodes,
    seriesSeason,
    seriesEpisode,
    isTrailer,
    trailerProgressBar,
    trailerProgressCount,
    file,
  } = useSelector((state) => state.upload);
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [group, setGroup] = useState({
    name: "",
    description: "",
    category: "",
    categoryId: "",
    image: "",
  });
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const canvasRef = useRef(null);
  const ffmpegRef = useRef(new FFmpeg());
  const [frames, setFrames] = useState([]);
  const [src2, setSrc2] = useState(null);
  const [crop2, setCrop2] = useState(null);
  const imgRef2 = useRef(null);
  const canvasRef2 = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const load = async () => {
    const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
    const ffmpeg = ffmpegRef.current;
    try {
      await ffmpeg.load({
        coreURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.js`,
          "text/javascript"
        ),
        wasmURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.wasm`,
          "application/wasm"
        ),
      });
      console.log("loading");
      extractFrames(videoUrl);
      console.log("done");
    } catch (error) {
      console.error("Error loading FFmpeg:", error);
    }
  };

  useEffect(() => {
    load();
  }, []);

  const extractFrames = async (videoUrl) => {
    const ffmpeg = ffmpegRef.current;
    try {
      const videoURL = videoUrl;

      const videoData = await fetchFile(videoURL);

      await ffmpeg.writeFile("input.mp4", videoData);

      // Verify file exists
      const files = await ffmpeg.listDir("/");

      // Extract frames at specific timestamps
      const timestamps = ["00:00:01", "00:00:03"]; // Extract frames at 1s and 2s
      const outputFilenames = ["frame1.jpg", "frame2.jpg"];

      for (let i = 0; i < timestamps.length; i++) {
        await ffmpeg.exec([
          "-i",
          "input.mp4",
          "-ss",
          timestamps[i],
          "-frames:v",
          "1",
          outputFilenames[i],
        ]);
      }
      // Read extracted frames and convert them to blobs
      const extractedFrames = await Promise.all(
        outputFilenames.map(async (filename) => {
          const frameData = await ffmpeg.readFile(filename);
          const blob = new Blob([frameData], { type: "image/jpeg" }); // Remove .buffer
          return URL.createObjectURL(blob);
        })
      );

      setFrames(extractedFrames);
    } catch (error) {
      console.error("Error extracting frames:", error);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!poster) {
      toast.error("Please upload a Thumbnail");
      return;
    }
    setLoading(true);
    const res = await api.post("/videotemplate/store-video-template-data", {
      videoUrl: videoUrl,
      name: title,
      description: description,
      categoryID: user.id,
      templateGroupID: groupId,
      episodNumber: "0",
      timeStampFace: [],
      timeStampAudio: [],
      speakers: [],
      UserDataWithTime: [],
      status: 0,
      type: "user-video",
    });

    const update = await api.post("/videotemplate/update-video-template-data", {
      id: res.data.userVideoDurationData.id,
      videoTemplateData: {
        imageUrl: poster,
        isAprove: 0,
        prompt: prompt,
        seed: seed,
        seriesSeason: seriesSeason,
        seriesEpisode: seriesEpisode,
        userId: user.id,
      },
    });
    // console.log(update);
    // console.log(res);
    setLoading(false);
    dispatch(clearVideo());
    mixpanel.track("user uploaded a series", { url: videoUrl });
    navigate(-1);
    toast.success("Series send for approval!");
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setCrop(
      centerCrop(
        makeAspectCrop(
          {
            unit: "px",
            width: width, // Set width to full (video or image width)
            height: (width * 9) / 16, // Calculate height based on 16:9 aspect ratio
          },
          16 / 9, // Maintain 16:9 aspect ratio
          width,
          height
        ),
        width,
        height
      )
    );
  };

  const onImageLoad2 = (e) => {
    const { width, height } = e.currentTarget;
    setCrop2(
      centerCrop(
        makeAspectCrop(
          {
            unit: "px",
            width: width, // Set width to full (video or image width)
            height: (width * 9) / 16, // Calculate height based on 16:9 aspect ratio
          },
          16 / 9, // Maintain 16:9 aspect ratio
          width,
          height
        ),
        width,
        height
      )
    );
  };
  const handleCrop = async (type) => {
    if (!completedCrop || !canvasRef.current || !imgRef.current) {
      return;
    }
    setSrc(null);
    const image = imgRef.current;
    const canvas = canvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob(
      async (blob) => {
        if (blob) {
          const file = new File([blob], "cropped-image.jpg", {
            type: "image/jpeg",
          });
          setImageLoading(true);
          try {
            const url = await uploadFileOnS3({ file, key: "image4upload" });
            console.log(url);
            if (type) {
              setGroup({ ...group, image: url });
            } else {
              dispatch(setPoster(url));
            }
          } catch (error) {
            toast.error("error uploading image");
            console.log(error);
          } finally {
            setImageLoading(false);
          }
        }
      },
      "image/jpeg",
      1
    );
  };

  const handleCrop2 = async () => {
    if (!completedCrop || !canvasRef2.current || !imgRef2.current) {
      return;
    }
    setSrc2(null);
    const image = imgRef2.current;
    const canvas = canvasRef2.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob(
      async (blob) => {
        const file = new File([blob], "cropped-image.jpg", {
          type: "image/jpeg",
        });
        setImageLoading(true);
        try {
          const url = await uploadFileOnS3({ file, key: "image4upload" });
          console.log(url);
          setGroup({ ...group, image: url });
        } catch (error) {
          toast.error("error uploading image");
          console.log(error);
        } finally {
          setImageLoading(false);
        }
      },
      "image/jpeg",
      1
    );
  };
  const handleReset = () => {
    dispatch(clearVideo());
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(videoUrl);
    toast.success("Link copied to clipboard!");
  };

  const handleFileChange = async (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log("result,", reader.result);
        setSrc(reader.result); // Set the image source for cropping
      };
      reader.readAsDataURL(file);
    } else {
      toast.error("Please select an image");
    }
  };
  const handleFileChange2 = async (e) => {
    if (e.target.files) {
      const reader = new FileReader();
      reader?.addEventListener("load", () => setSrc2(reader.result));
      reader?.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <>
      <div className="flex justify-between border-b-2 border-zinc-700">
        <h1 className="text-xl font-bold mb-4">{title || "@"}</h1>
        <X onClick={handleReset} size={25} className="cursor-pointer" />
      </div>
      <div className="flex flex-col-reverse md:flex-row gap-5 mt-5">
        <div className="flex flex-col w-full">
          <h1 className="text-xl font-bold mb-4">Video Details</h1>
          <form onSubmit={handleSubmit}>
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-300 ml-1 mb-1"
            >
              Title
            </label>
            <input
              id="title"
              type="text"
              value={title}
              className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
              required
              onChange={(e) => dispatch(setTitle(e.target.value))}
            />
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-300 ml-1 mb-1"
            >
              Description
            </label>
            <textarea
              id="description"
              value={description}
              rows="3"
              onChange={(e) => dispatch(setDescription(e.target.value))}
              className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
              required
            />

            <>
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-300 ml-1 mb-1"
              >
                Prompt
              </label>
              <textarea
                id="prompt"
                value={prompt}
                rows="2"
                onChange={(e) => dispatch(setPrompt(e.target.value))}
                className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                required
              />
            </>

            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-300 ml-1 mb-1"
            >
              Seed
            </label>
            <input
              id="seed"
              type="text"
              value={seed}
              className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
              required
              onChange={(e) => dispatch(seetSeed(e.target.value))}
            />

            <label className="block text-sm font-medium text-gray-300 ml-1 mb-1">
              Season
            </label>
            <div className="flex gap-2">
              <select
                className="block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                onChange={(e) => dispatch(setSeriesSeason(e.target.value))}
                value={seriesSeason}
                required
              >
                <option value="">Select a season</option>
                <option key={1} value={1}>
                  1
                </option>
                <option key={2} value={2}>
                  2
                </option>
                <option key={3} value={3}>
                  3
                </option>
              </select>
            </div>
            <label className="block text-sm font-medium text-gray-300 ml-1 mb-1 mt-2">
              Episode
            </label>
            <div className="flex gap-2">
              <select
                className="block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                onChange={(e) => dispatch(setSeriesEpisode(e.target.value))}
                value={seriesEpisode}
                required
              >
                <option value="">Select episode</option>

                <option key={1} value={1}>
                  1
                </option>
                <option key={2} value={2}>
                  2
                </option>
                <option key={3} value={3}>
                  3
                </option>
              </select>
            </div>

            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600  mt-4"
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <LoaderCircle className="animate-spin mr-2" size={22} />
                </div>
              ) : (
                "Submit for Review"
              )}
            </button>
          </form>
        </div>
        <div className="flex flex-col md:w-1/3">
          <h1 className="text-xl font-bold mb-4">Video Preview</h1>
          {videoUrl && (
            <>
              <video className="w-full mb-4" controls src={videoUrl} />
              Video Link
              <div className="flex items-center gap-2">
                <div className="text-sm text-gray-400 flex-1 line-clamp-1">
                  {videoUrl}
                </div>
                <Copy
                  className="cursor-pointer"
                  size={18}
                  onClick={copyToClipboard}
                />
              </div>
              <div>
                {frames.length > 0 && (
                  <h1 className="text-base mb-1 mt-4">Select from video</h1>
                )}
                <div className="flex flex-wrap gap-2">
                  {frames.map((frame, index) => (
                    <img
                      key={index}
                      src={frame}
                      alt={`Frame ${index + 1}`}
                      style={{
                        width: "150px",
                        height: "auto",
                        border: "1px solid #ccc",
                      }}
                      onClick={() => setSrc(frame)}
                    />
                  ))}
                </div>
              </div>
              <div className="flex justify-between items-center mt-4 mb-2">
                <h1 className="text-xl font-bold ">Thumbnail</h1>
                {poster && (
                  <Trash
                    className="cursor-pointer"
                    size={20}
                    onClick={() => dispatch(setPoster(null))}
                  />
                )}
              </div>
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={(e) => handleFileChange(e, 0)}
                id="file-image"
              />
              {imageLoading ? (
                <div className="m-auto mt-5">
                  <LoaderCircle className="animate-spin m-auto" size={22} />
                  <div className="text-sm text-gray-400 text-center">
                    Uplaod Image...
                  </div>
                </div>
              ) : poster ? (
                <img
                  className="w-full aspect-video cursor-pointer"
                  src={poster}
                  alt="Thumbnail"
                  onClick={() => document.getElementById("file-image").click()}
                />
              ) : (
                <>
                  {src ? (
                    <div>
                      <ReactCrop
                        crop={crop}
                        onChange={(newCrop) => setCrop(newCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={16 / 9}
                      >
                        <img
                          ref={imgRef}
                          alt="Crop me"
                          src={src}
                          onLoad={onImageLoad}
                          className="max-w-full"
                        />
                      </ReactCrop>
                      <canvas ref={canvasRef} style={{ display: "none" }} />
                      <div className="flex gap-2">
                        <button
                          onClick={() => setSrc(null)}
                          className="bg-red-500 text-white px-4 py-2 mt-4 rounded w-full"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => handleCrop(0)}
                          className="bg-blue-500 text-white px-4 py-2 mt-4 rounded w-full"
                        >
                          Crop & Upload
                        </button>
                      </div>
                    </div>
                  ) : (
                    <label htmlFor="file-image" className="cursor-pointer">
                      <div className="flex items-center text-center justify-center w-full h-40 border-2 border-dashed border-zinc-600 rounded-lg hover:bg-zinc-800">
                        Upload Thumbnail
                      </div>
                    </label>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
