import Footer from "../components/Footer";
import Loader from "../components/UI/Loader";
import ErrorMessage from "../components/UI/ErrorMEssage";
import Player from "../components/player/Player";
import Episodes from "../components/Episodes";
import Header from "../components/Header";
import Trailer from "../components/Trailer";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { getEpisodes } from "../redux/slice/Movie";
// import Comments from "../components/Comments";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import NewSeason from "../components/newSeason/NewSeason";
export default function Movie() {
  const { group, episodes, movie, error, movieLoading } = useSelector(
    (state) => state.movie
  );
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { id, item } = state || {};
  useEffect(() => {
    if (state == null) {
      navigate("/");
      return;
    }
    dispatch(getEpisodes({ id, item: item }));
    mixpanel.track("Watched a video", {
      id,
      title: item?.trailer?.name || item?.title,
    });
  }, []);
  if (error) {
    return <ErrorMessage message={error} />;
  }
  return (
    <div>
      <Header />
      {movieLoading ? (
        <Loader message="loading..." />
      ) : (
        <>
          <Player movie={movie} />
          {/* <NewSeason item={item} /> */}
          {group?.trailer?.videoUrl && group?.trailer?.imageUrl && <Trailer />}
          {episodes?.length > 0 && <Episodes />}
          {/* <Comments id={id} item={item} /> */}
          <Footer />
        </>
      )}
    </div>
  );
}
