import React from "react";
import { useParams } from "react-router-dom";
import UploadVideo from "../components/AddNewSeason/uploadVideo";
import Details from "../components/AddNewSeason/Details";
import { useSelector } from "react-redux";
function AddNewSeason() {
  const { id } = useParams();
  const { step, series } = useSelector((state) => state.upload);
  const renderSteps = () => {
    if (step == 1) return <UploadVideo />;
    else if (step == 2) return <Details groupId={id} />;
    else return <UploadVideo />;
  };

  return (
    <div className="flex items-center justify-center h-screen sm:m-4 overflow-hidden">
      <div className="bg-zinc-800 p-4 sm:px-8 sm:py-4 rounded-xl shadow-md w-full max-w-5xl h-full relative overflow-scroll">
        {renderSteps()}
      </div>
    </div>
  );
}

export default AddNewSeason;
