import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

export const fetchepisode = createAsyncThunk(
  "fetchTemplate",
  async ({ templateID }) => {
    try {
      const allTemplates = await api.post("/videotemplate/get-template-list", {
        limit: 1000,
        lastKey: null,
      });
      const data = allTemplates.data.searchData.filter(
        (item) => item.id === templateID
      )[0];
      const episodes = allTemplates.data.searchData
        .filter((item) => item.templateGroupID === data.templateGroupID)
        .sort((a, b) => a.episodNumber - b.episodNumber);
      if (!data) {
        throw new Error("404, video not found");
      }
      return {
        allTemplates: allTemplates.data,
        data,
        episodes,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const allGroups = createAsyncThunk(
  "fetchTemplate2",
  async (_, { getState }) => {
    try {
      const [allGroups, for_you, trending, popular] = await Promise.all([
        await api.post("/templategroup/get-groupData-list", {
          limit: 1000,
          lastKey: null,
        }),
        await api.post("/userpodcast/get-user-podcasts-by-category", {
          podcastCategory: "for_you",
          limit: 100,
          lastKey: null,
        }),
        await api.post("/userpodcast/get-user-podcasts-by-category", {
          podcastCategory: "trending",
          limit: 100,
          lastKey: null,
        }),
        await api.post("/userpodcast/get-user-podcasts-by-category", {
          podcastCategory: "popular",
          limit: 100,
          lastKey: null,
        }),
      ]);

      return {
        allGroups: allGroups.data,
        for_you: for_you.data.items,
        trending: trending.data.items,
        popular: popular.data.items,
      };
    } catch (error) {
      console.log("movies slice error ", error);
      throw error;
    }
  }
);

export const fetchContinueWatch = createAsyncThunk(
  "fetchContinueWatch",
  async (_, { getState }) => {
    try {
      const state = getState();
      const userId = state.auth.user.id;
      const continueWatch = await api.post(
        "/videosubscription/get-user-subscription-data",
        {
          userID: userId,
        }
      );
      return continueWatch.data;
    } catch (error) {
      console.log("error in fetchContinueWatch", error);
    }
  }
);

export const getEpisodes = createAsyncThunk(
  "getEpisodesx",
  async ({ id, item }) => {
    try {
      const res = await api.post(
        "/videotemplate/get-template-group-search-data",
        { templateGroupID: id }
      );
      return { data: res.data.searchData, item };
    } catch (error) {
      console.log("error in fetching episodes", error);
    }
  }
);

const initialState = {
  videoUrl: null,
  allTemplates: null,
  continueWatch: null,
  allGroups: null,
  allOtherGroups: null,
  for_you: null,
  trending: null,
  popular: null,
  movieLoading: false,
  movie: null,
  group: null,
  episodes: null,
  error: "",
  loading: false,
  loadingGroups: false,
};

const movieSlice = createSlice({
  name: "movieSlice",
  initialState,
  reducers: {
    setMovie: (state, action) => {
      state.movie = action.payload;
    },

    setEpisodes: (state, action) => {
      const data = state.allTemplates
        .filter((item) => item.templateGroupID === action.payload)
        .sort((a, b) => a.episodNumber - b.episodNumber);
      state.movie = data[0];
      state.episodes = data;
    },
    addContinueWatch: (state, action) => {
      state.continueWatch = [...state.continueWatch, action.payload];
    },
    updateContinueWatch: (state, action) => {
      const index = state.continueWatch.findIndex(
        (item) => item.podcastID === action.payload.id
      );
      if (index !== -1) {
        state.continueWatch[index] = {
          ...state.continueWatch[index],
          end_time: action.payload.endTime,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchepisode.pending, (state, action) => {
      state.error = "";
      state.loading = true;
    });
    builder.addCase(fetchepisode.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
    builder.addCase(fetchepisode.fulfilled, (state, action) => {
      state.movie = action.payload.data;
      state.allTemplates = action.payload.allTemplates;
      state.episodes = action.payload.episodes;
      state.error = "";
      state.loading = false;
    });

    builder.addCase(allGroups.pending, (state, action) => {
      state.loadingGroups = true;
    });
    builder.addCase(allGroups.rejected, (state, action) => {
      state.loadingGroups = false;
    });
    builder.addCase(allGroups.fulfilled, (state, action) => {
      state.allGroups = action.payload.allGroups.searchData
        .filter((item) => item.status === 1)
        .sort(() => Math.random() - 0.5)
        .splice(0, 10);
      state.allOtherGroups = action.payload.allGroups.searchData;
      state.for_you = action.payload.for_you;

      const sortedTrending = [...action.payload.trending].sort((a, b) => {
        const orderA = Number(a.order) || 0;
        const orderB = Number(b.order) || 0;
        if (orderA === 0 && orderB !== 0) return 1;
        if (orderB === 0 && orderA !== 0) return -1;

        return orderA - orderB;
      });
      state.trending = sortedTrending;

      const sortedPop = [...action.payload.popular].sort((a, b) => {
        const orderA = Number(a.order) || 0;
        const orderB = Number(b.order) || 0;
        if (orderA === 0 && orderB !== 0) return 1;
        if (orderB === 0 && orderA !== 0) return -1;

        return orderA - orderB;
      });
      state.popular = sortedPop;
      state.loadingGroups = false;
    });

    builder.addCase(fetchContinueWatch.fulfilled, (state, action) => {
      state.continueWatch = action.payload;
    });

    builder.addCase(getEpisodes.pending, (state, action) => {
      state.movieLoading = true;
    });
    builder.addCase(getEpisodes.rejected, (state, action) => {
      state.movieLoading = false;
    });
    builder.addCase(getEpisodes.fulfilled, (state, action) => {
      state.movieLoading = false;
      const sortD = action.payload.data.filter(
        (item) => item.status == 1 && item.isAprove == 1
      );
      const data = sortD.sort((a, b) => a.episodNumber - b.episodNumber);
      state.movie = data[0];
      state.episodes = data;
      state.group = action.payload.item;
    });
  },
});

export const { setMovie, setEpisodes, addContinueWatch, updateContinueWatch } =
  movieSlice.actions;
export default movieSlice.reducer;
