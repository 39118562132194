import { useDispatch } from "react-redux";
import { setSteps, setVideoFile } from "../../redux/slice/Upload";
import { useState } from "react";
import { s3 } from "../../utils/aws";
import { LoaderCircle, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
function UploadVideo() {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    uploadToS3(file);
    // dispatch(
    //   setVideoFile({
    //     url: "https://static-content-for-text.s3.amazonaws.com/mergedVideos/413d9477-b312-4678-b452-54f0c63a6917-merged.mp4",
    //     name: "ademo name",
    //     isTrailer: true,
    //     step: 4,
    //   })
    // );
  };

  const uploadToS3 = async (file) => {
    setUploading(true);
    const params = {
      Bucket: "static-content-for-text",
      Key: `web4creator/${Date.now() + "-" + file.name}`,
      Body: file,
      ContentType: file.type,
    };
    const options = {
      partSize: 5 * 1024 * 1024,
      queueSize: 1,
    };

    s3.upload(params, options)
      .on("httpUploadProgress", (event) => {
        const percentage = Math.round((event.loaded / event.total) * 100);
        setProgress(percentage);
      })
      .send((err, data) => {
        if (err) {
          console.error("Upload failed:", err);
          toast.error("Error uploading file");
        } else {
          console.log("Upload successful:", data);
          console.log(data.Location);
          mixpanel.track("Trailer uploaded", {
            url: data.Location,
          });
          dispatch(
            setVideoFile({
              url: data.Location,
              name: file.name,
              isTrailer: true,
              step: 4,
            })
          );
          setUploading(false);
        }
      });
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      uploadToS3(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="flex justify-between border-b-2 border-zinc-700">
        <h1 className="text-xl font-bold mb-4">Upload Trailer</h1>
        <X
          onClick={() => dispatch(setSteps(1))}
          size={25}
          className="cursor-pointer"
        />
      </div>
      <div className="flex flex-col items-center justify-center h-[90%] max-w-xl m-auto">
        {uploading ? (
          <>
            <div className="justify-center text-gray-500 text-sm items-center flex gap-2 mt-10 mb-2 w-full">
              uploading file <LoaderCircle className="animate-spin" size={14} />
            </div>
            <div className="flex items-center gap-2 w-full">
              <div className="h-2 w-full bg-gray-100 rounded-full overflow-hidden ">
                <div
                  style={{ width: progress + "%" }}
                  className="h-full rounded-full bg-blue-400"
                />
              </div>
              <span className="font-bold">{progress}%</span>
            </div>
          </>
        ) : (
          <>
            <div
              className="border-2 border-dashed border-gray-300 rounded-lg p-4 text-center cursor-pointer h-40 flex items-center justify-center w-full"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onClick={() => document.getElementById("file-input").click()}
            >
              <p className="text-gray-500">
                Drag & drop your video here or click to select
              </p>
            </div>
            <input
              type="file"
              accept="video/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="file-input"
            />
            <label
              htmlFor="file-input"
              className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 cursor-pointer block text-center mt-4"
            >
              Select Video
            </label>
          </>
        )}
      </div>
    </>
  );
}

export default UploadVideo;
