import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearVideo,
  setTitle,
  setDescription,
  setPoster,
  addSeries,
  addSelectedSeries,
  editSeries,
  addTrailerProgressBar,
  addTrailerProgressCount,
  setPrompt,
  seetSeed,
} from "../../redux/slice/Upload";
import { uploadFileOnS3 } from "../../utils/aws";
import { Copy, Plus, X, LoaderCircle, Trash } from "lucide-react";
import { api } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import mixpanel from "mixpanel-browser";

export default function Details() {
  const {
    videoUrl,
    title,
    description,
    poster,
    series,
    prompt,
    seed,
    selectedSeries,
    noOfEpisodes,
    isTrailer,
    trailerProgressBar,
    trailerProgressCount,
    file,
  } = useSelector((state) => state.upload);
  console.log(trailerProgressBar, trailerProgressCount);
  const { user } = useSelector((state) => state.auth);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [group, setGroup] = useState({
    name: "",
    description: "",
    category: "",
    categoryId: "",
    image: "",
  });
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const canvasRef = useRef(null);
  const ffmpegRef = useRef(new FFmpeg());
  const [frames, setFrames] = useState([]);
  const [src2, setSrc2] = useState(null);
  const [crop2, setCrop2] = useState(null);
  const imgRef2 = useRef(null);
  const canvasRef2 = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const load = async () => {
    const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
    const ffmpeg = ffmpegRef.current;
    try {
      await ffmpeg.load({
        coreURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.js`,
          "text/javascript"
        ),
        wasmURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.wasm`,
          "application/wasm"
        ),
      });
      console.log("loading");
      extractFrames(videoUrl);
      console.log("done");
    } catch (error) {
      console.error("Error loading FFmpeg:", error);
    }
  };

  useEffect(() => {
    load();
  }, []);

  const extractFrames = async (videoUrl) => {
    const ffmpeg = ffmpegRef.current;
    try {
      const videoURL = videoUrl;

      const videoData = await fetchFile(videoURL);

      await ffmpeg.writeFile("input.mp4", videoData);

      // Verify file exists
      const files = await ffmpeg.listDir("/");

      // Extract frames at specific timestamps
      const timestamps = ["00:00:01", "00:00:03"]; // Extract frames at 1s and 2s
      const outputFilenames = ["frame1.jpg", "frame2.jpg"];

      for (let i = 0; i < timestamps.length; i++) {
        await ffmpeg.exec([
          "-i",
          "input.mp4",
          "-ss",
          timestamps[i],
          "-frames:v",
          "1",
          outputFilenames[i],
        ]);
      }
      // Read extracted frames and convert them to blobs
      const extractedFrames = await Promise.all(
        outputFilenames.map(async (filename) => {
          const frameData = await ffmpeg.readFile(filename);
          const blob = new Blob([frameData], { type: "image/jpeg" }); // Remove .buffer
          return URL.createObjectURL(blob);
        })
      );

      setFrames(extractedFrames);
    } catch (error) {
      console.error("Error extracting frames:", error);
    }
  };
  const getCateId = (e) => {
    if (e.target.value == "Educational") {
      setGroup({
        ...group,
        categoryId: "641f1c52-b071-469c-b3e3-fdbf0766fff9",
        category: e.target.value,
      });
    } else if (e.target.value == "romance") {
      setGroup({
        ...group,
        categoryId: "d40c6afd-a392-4f5d-8f8a-18db20fbcbe6",
        category: e.target.value,
      });
    } else if (e.target.value == "Thriller") {
      setGroup({
        ...group,
        categoryId: "f8dba1f3-4286-4266-a04b-22f16f6027db",
        category: e.target.value,
      });
    } else if (e.target.value == "SciFi") {
      setGroup({
        ...group,
        categoryId: "38cc137a-b595-42d5-a22f-617c80f0937f",
        category: e.target.value,
      });
    } else if (e.target.value == "comedy") {
      setGroup({
        ...group,
        categoryId: "d3334e83-51a9-4a99-bd97-0affceb95965",
        category: e.target.value,
      });
    } else if (e.target.value == "horror") {
      setGroup({
        ...group,
        categoryId: "7b39a208-0add-49ce-a4ae-c30d45ee4a67",
        category: e.target.value,
      });
    } else if (e.target.value == "adventure") {
      setGroup({
        ...group,
        categoryId: "c03c2914-66c7-4486-8a23-7b0f68bb3853",
        category: e.target.value,
      });
    } else if (e.target.value == "drama") {
      setGroup({
        ...group,
        categoryId: "f7040bf9-a8a8-4244-aaef-6d58f28484d4",
        category: e.target.value,
      });
    } else if (e.target.value == "inspirational") {
      setGroup({
        ...group,
        categoryId: "d060505b-31de-44e6-a6c0-b472537e9cda",
        category: e.target.value,
      });
    } else {
      setGroup({
        ...group,
        categoryId: "7b2ad184-0cda-487e-af0a-55d9dd0b3720",
        category: e.target.value,
      });
    }
  };
  const handleSelectSeries = (e) => {
    dispatch(addSelectedSeries(e.target.value));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!poster) {
      toast.error("Please upload a Thumbnail");
      return;
    }
    setLoading(true);
    if (isTrailer) {
      const res = await api.post("/templategroup/update-template-group-data", {
        id: selectedSeries,
        groupData: {
          trailer: {
            videoUrl: videoUrl,
            name: title,
            description: description,
            imageUrl: poster,
          },
          trailerProgressBar: trailerProgressBar,
          trailerProgressNumber: trailerProgressCount,
          trailerProgressCount: 0,
        },
      });
      mixpanel.track("user uploaded a trailer", { url: videoUrl });
      dispatch(editSeries({ id: selectedSeries, data: res.data.updatedData }));
    } else {
      const res = await api.post("/videotemplate/store-video-template-data", {
        videoUrl: videoUrl,
        name: title,
        description: description,
        categoryID: user.id,
        templateGroupID: selectedSeries,
        episodNumber: noOfEpisodes + 1,
        timeStampFace: [],
        timeStampAudio: [],
        speakers: [],
        UserDataWithTime: [],
        status: 0,
        type: "user-video",
      });

      const update = await api.post(
        "/videotemplate/update-video-template-data",
        {
          id: res.data.userVideoDurationData.id,
          videoTemplateData: {
            imageUrl: poster,
            isAprove: 0,
            prompt: prompt,
            seed: seed,
          },
        }
      );
    }
    // console.log(update);
    // console.log(res);
    setLoading(false);
    dispatch(clearVideo());
    mixpanel.track("user uploaded a series", { url: videoUrl });
    navigate(-1);
    toast.success(
      isTrailer ? "Trailer send for approval!" : "Video send for approval!"
    );
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setCrop(
      centerCrop(
        makeAspectCrop(
          {
            unit: "px",
            width: width, // Set width to full (video or image width)
            height: (width * 9) / 16, // Calculate height based on 16:9 aspect ratio
          },
          16 / 9, // Maintain 16:9 aspect ratio
          width,
          height
        ),
        width,
        height
      )
    );
  };

  const onImageLoad2 = (e) => {
    const { width, height } = e.currentTarget;
    setCrop2(
      centerCrop(
        makeAspectCrop(
          {
            unit: "px",
            width: width, // Set width to full (video or image width)
            height: (width * 9) / 16, // Calculate height based on 16:9 aspect ratio
          },
          16 / 9, // Maintain 16:9 aspect ratio
          width,
          height
        ),
        width,
        height
      )
    );
  };
  const handleCrop = async (type) => {
    if (!completedCrop || !canvasRef.current || !imgRef.current) {
      return;
    }
    setSrc(null);
    const image = imgRef.current;
    const canvas = canvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob(
      async (blob) => {
        if (blob) {
          const file = new File([blob], "cropped-image.jpg", {
            type: "image/jpeg",
          });
          setImageLoading(true);
          try {
            const url = await uploadFileOnS3({ file, key: "image4upload" });
            console.log(url);
            if (type) {
              setGroup({ ...group, image: url });
            } else {
              dispatch(setPoster(url));
            }
          } catch (error) {
            toast.error("error uploading image");
            console.log(error);
          } finally {
            setImageLoading(false);
          }
        }
      },
      "image/jpeg",
      1
    );
  };

  const handleCrop2 = async () => {
    if (!completedCrop || !canvasRef2.current || !imgRef2.current) {
      return;
    }
    setSrc2(null);
    const image = imgRef2.current;
    const canvas = canvasRef2.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob(
      async (blob) => {
        const file = new File([blob], "cropped-image.jpg", {
          type: "image/jpeg",
        });
        setImageLoading(true);
        try {
          const url = await uploadFileOnS3({ file, key: "image4upload" });
          console.log(url);
          setGroup({ ...group, image: url });
        } catch (error) {
          toast.error("error uploading image");
          console.log(error);
        } finally {
          setImageLoading(false);
        }
      },
      "image/jpeg",
      1
    );
  };
  const handleReset = () => {
    dispatch(clearVideo());
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(videoUrl);
    toast.success("Link copied to clipboard!");
  };

  const handleFileChange = async (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log("result,", reader.result);
        setSrc(reader.result); // Set the image source for cropping
      };
      reader.readAsDataURL(file);
    } else {
      toast.error("Please select an image");
    }
  };
  const handleFileChange2 = async (e) => {
    if (e.target.files) {
      const reader = new FileReader();
      reader?.addEventListener("load", () => setSrc2(reader.result));
      reader?.readAsDataURL(e.target.files[0]);
    }
  };
  const handleCreateGroup = async (e) => {
    e.preventDefault();
    if (!group.image) {
      toast.error("Please select an image");
      return;
    }
    setLoading(true);
    const res = await api.post("/templategroup/store-template-group-data", {
      name: group.name,
      templateGroupProfile: group.image,
      description: group.description,
      prompt: "",
      type: "user-groups",
    });

    const update = await api.post("/templategroup/update-template-group-data", {
      id: res.data.categoryData.id,
      groupData: {
        categoryID: group.categoryId,
        userId: user.id,
        trailer: null,
        status: 0,
        categoryLabel: group.category,
      },
    });
    console.log(update.data);
    setLoading(false);
    setModal(false);
    setGroup({
      name: "",
      description: "",
      image: "",
    });
    toast.success("Series created successfully!");
    dispatch(addSeries(res.data.categoryData));
  };
  return (
    <>
      <div className="flex justify-between border-b-2 border-zinc-700">
        <h1 className="text-xl font-bold mb-4">{title || "@"}</h1>
        <X onClick={handleReset} size={25} className="cursor-pointer" />
      </div>
      <div className="flex flex-col-reverse md:flex-row gap-5 mt-5">
        <div className="flex flex-col w-full">
          <h1 className="text-xl font-bold mb-4">
            {isTrailer ? "Trailer" : "Video"} Details
          </h1>
          <form onSubmit={handleSubmit}>
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-300 ml-1 mb-1"
            >
              Title
            </label>
            <input
              id="title"
              type="text"
              value={title}
              className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
              required
              onChange={(e) => dispatch(setTitle(e.target.value))}
            />
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-300 ml-1 mb-1"
            >
              Description
            </label>
            <textarea
              id="description"
              value={description}
              rows="3"
              onChange={(e) => dispatch(setDescription(e.target.value))}
              className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
              required
            />
            {!isTrailer && (
              <>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-300 ml-1 mb-1"
                >
                  Prompt
                </label>
                <textarea
                  id="prompt"
                  value={prompt}
                  rows="2"
                  onChange={(e) => dispatch(setPrompt(e.target.value))}
                  className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                  required
                />
              </>
            )}
            {!isTrailer && (
              <>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-300 ml-1 mb-1"
                >
                  Seed
                </label>
                <input
                  id="seed"
                  type="text"
                  value={seed}
                  className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                  required
                  onChange={(e) => dispatch(seetSeed(e.target.value))}
                />
              </>
            )}
            <label className="block text-sm font-medium text-gray-300 ml-1 mb-1">
              series
            </label>
            <div className="flex gap-2">
              <select
                className="block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                onChange={handleSelectSeries}
                value={selectedSeries}
                required
              >
                <option value="">Select an series</option>
                {series.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <div
                className="cursor-pointer bg-blue-500 rounded-lg justify-center items-center flex w-12 "
                onClick={() => setModal(true)}
              >
                <Plus size={25} />
              </div>
            </div>
            {isTrailer && (
              <div className="mb-5">
                <label className="mt-2 block text-sm font-medium text-gray-300 ml-1 mb-1">
                  Trailer Reach Count
                </label>
                <div className="flex gap-2">
                  <div className="flex items-center ml-2">
                    <input
                      type="checkbox"
                      id="trailerCount"
                      className="block w-5 h-5 border-2 border-zinc-600 rounded-md p-3  bg-zinc-800 focus:border-blue-500 outline-none hover:cursor-pointer"
                      onChange={(e) =>
                        dispatch(addTrailerProgressBar(e.target.checked))
                      }
                      checked={trailerProgressBar}
                    />
                    <label
                      for="trailerCount"
                      className="ml-2 text-sm text-gray-400"
                    >
                      {trailerProgressBar ? "Enabled" : "Disabled"}
                    </label>
                  </div>
                  {trailerProgressBar && (
                    <input
                      id="trailerCount"
                      type="text"
                      placeholder="Trailer Reach Count"
                      value={trailerProgressCount ? trailerProgressCount : ""}
                      className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                      required
                      onChange={(e) =>
                        dispatch(addTrailerProgressCount(e.target.value))
                      }
                    />
                  )}
                </div>
              </div>
            )}
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600  mt-4"
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <LoaderCircle className="animate-spin mr-2" size={22} />
                </div>
              ) : isTrailer ? (
                "Publish Trailer"
              ) : (
                "Submit for Review"
              )}
            </button>
          </form>
        </div>
        <div className="flex flex-col md:w-1/3">
          <h1 className="text-xl font-bold mb-4">
            {isTrailer ? "Trailer" : "Video"} Preview
          </h1>
          {videoUrl && (
            <>
              <video className="w-full mb-4" controls src={videoUrl} />
              Video Link
              <div className="flex items-center gap-2">
                <div className="text-sm text-gray-400 flex-1 line-clamp-1">
                  {videoUrl}
                </div>
                <Copy
                  className="cursor-pointer"
                  size={18}
                  onClick={copyToClipboard}
                />
              </div>
              <div>
                {frames.length > 0 && (
                  <h1 className="text-base mb-1 mt-4">Select from video</h1>
                )}
                <div className="flex flex-wrap gap-2">
                  {frames.map((frame, index) => (
                    <img
                      key={index}
                      src={frame}
                      alt={`Frame ${index + 1}`}
                      style={{
                        width: "150px",
                        height: "auto",
                        border: "1px solid #ccc",
                      }}
                      onClick={() => setSrc(frame)}
                    />
                  ))}
                </div>
              </div>
              <div className="flex justify-between items-center mt-4 mb-2">
                <h1 className="text-xl font-bold ">Thumbnail</h1>
                {poster && (
                  <Trash
                    className="cursor-pointer"
                    size={20}
                    onClick={() => dispatch(setPoster(null))}
                  />
                )}
              </div>
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={(e) => handleFileChange(e, 0)}
                id="file-image"
              />
              {imageLoading ? (
                <div className="m-auto mt-5">
                  <LoaderCircle className="animate-spin m-auto" size={22} />
                  <div className="text-sm text-gray-400 text-center">
                    Uplaod Image...
                  </div>
                </div>
              ) : poster ? (
                <img
                  className="w-full aspect-video cursor-pointer"
                  src={poster}
                  alt="Thumbnail"
                  onClick={() => document.getElementById("file-image").click()}
                />
              ) : (
                <>
                  {src ? (
                    <div>
                      <ReactCrop
                        crop={crop}
                        onChange={(newCrop) => setCrop(newCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={16 / 9}
                      >
                        <img
                          ref={imgRef}
                          alt="Crop me"
                          src={src}
                          onLoad={onImageLoad}
                          className="max-w-full"
                        />
                      </ReactCrop>
                      <canvas ref={canvasRef} style={{ display: "none" }} />
                      <div className="flex gap-2">
                        <button
                          onClick={() => setSrc(null)}
                          className="bg-red-500 text-white px-4 py-2 mt-4 rounded w-full"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => handleCrop(0)}
                          className="bg-blue-500 text-white px-4 py-2 mt-4 rounded w-full"
                        >
                          Crop & Upload
                        </button>
                      </div>
                    </div>
                  ) : (
                    <label htmlFor="file-image" className="cursor-pointer">
                      <div className="flex items-center text-center justify-center w-full h-40 border-2 border-dashed border-zinc-600 rounded-lg hover:bg-zinc-800">
                        Upload Thumbnail
                      </div>
                    </label>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {modal && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-60 overflow-y-auto"
          onClick={() => setModal(false)}
        >
          <div
            className="bg-zinc-800 p-4 rounded-lg relative w-full max-w-xl"
            onClick={(e) => e.stopPropagation()}
          >
            <X
              onClick={() => setModal(false)}
              size={25}
              className="cursor-pointer absolute top-4 right-4"
            />
            <h2 className="text-lg font-semibold mb-5">Create a series</h2>
            <form onSubmit={handleCreateGroup}>
              <label className="block text-sm font-medium text-gray-300 ml-1 mb-1">
                Name
              </label>
              <input
                type="text"
                className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                value={group.name}
                onChange={(e) => setGroup({ ...group, name: e.target.value })}
                required
              />
              <label className="block text-sm font-medium text-gray-300 ml-1 mb-1">
                Description
              </label>
              <textarea
                rows="5"
                className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                value={group.description.trim()}
                onChange={(e) =>
                  setGroup({ ...group, description: e.target.value })
                }
                required
              />
              <label className="block text-sm font-medium text-gray-300 ml-1 mb-1">
                Category
              </label>
              <select
                className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                onChange={(e) => getCateId(e)}
                required
              >
                <option value="">Select an category</option>
                <option value="Educational">Educational</option>
                <option value="romance">Romance</option>
                <option value="Thriller">Thriller</option>
                <option value="SciFi">SciFi</option>
                <option value="comedy">Comedy</option>
                <option value="horror">Horror</option>
                <option value="adventure">Adventure</option>
                <option value="drama">Drama</option>
                <option value="inspirational">Inspirational</option>
                <option value="other">Other</option>
              </select>
              <label className="block text-sm font-medium text-gray-300 ml-1 mb-1">
                Cover Image
              </label>
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={(e) => handleFileChange2(e, 1)}
                id="file-image-2"
              />
              {imageLoading ? (
                <div className="m-auto mt-5">
                  <LoaderCircle className="animate-spin m-auto" size={22} />
                  <div className="text-sm text-gray-400 text-center">
                    Uplaod Image...
                  </div>
                </div>
              ) : group?.image ? (
                <div className="relative">
                  <img
                    className="w-[60%] aspect-video cursor-pointer m-auto"
                    src={group.image}
                    alt="Image"
                    onClick={() =>
                      document.getElementById("file-image-2").click()
                    }
                  />

                  <Trash
                    className="cursor-pointer absolute bottom-2 right-2"
                    size={20}
                    onClick={() => setGroup({ ...group, image: null })}
                  />
                </div>
              ) : (
                <>
                  {src2 ? (
                    <div>
                      <ReactCrop
                        crop={crop2}
                        onChange={(newCrop) => setCrop2(newCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={16 / 9}
                      >
                        <img
                          ref={imgRef2}
                          alt="Crop me"
                          src={src2}
                          onLoad={onImageLoad2}
                          className="max-w-full"
                        />
                      </ReactCrop>
                      <canvas ref={canvasRef2} style={{ display: "none" }} />
                      <div className="flex gap-2">
                        <button
                          onClick={() => setSrc2(null)}
                          className="bg-red-500 text-white px-4 py-2 mt-4 rounded w-full"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => handleCrop2()}
                          className="bg-blue-500 text-white px-4 py-2 mt-4 rounded w-full"
                        >
                          Crop & Upload
                        </button>
                      </div>
                    </div>
                  ) : (
                    <label htmlFor="file-image-2" className="cursor-pointer">
                      <div className="flex items-center text-center justify-center w-full h-32 border-2 border-dashed border-zinc-600 rounded-lg hover:bg-zinc-800">
                        Upload Cover Image
                      </div>
                    </label>
                  )}
                </>
              )}
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 w-full mt-4"
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <LoaderCircle className="animate-spin mr-2" size={22} />
                  </div>
                ) : (
                  "Create Series"
                )}
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
