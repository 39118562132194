import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import mixpanel from "mixpanel-browser";

function NotFound() {
  // useEffect(() => {
  //   mixpanel.track("Navigate to 404 Page");
  // }, []);
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-zinc-900 text-white">
      <h1 className="text-6xl font-bold mb-4">404</h1>
      <p className="text-xl mb-4">Oops! Page Not Found</p>
      <p className="text-center mb-8">
        The page you are looking for might have been removedor is temporarily
        unavailable.
      </p>
      <Link
        to="/"
        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
      >
        Go to Home
      </Link>
    </div>
  );
}

export default NotFound;
