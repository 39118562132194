import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

export const addUSerSeries = createAsyncThunk(
  "upload/addSeriesWithExternalData",
  async (payload, { getState }) => {
    const { allOtherGroups } = getState().movie;
    const { user } = getState().auth;
    const series = allOtherGroups.filter((e) => e.userId == user.id);
    return series;
  }
);

export const getUserVideos = createAsyncThunk(
  "upload/getSeriesWithExternalData",
  async (payload, { getState }) => {
    const { user } = getState().auth;
    const res = await api.post("/videotemplate/get-template-by-categoryID", {
      categoryID: user.id,
    });
    return res.data.searchData;
  }
);

const upload = createSlice({
  name: "upload",
  initialState: {
    step: 1,
    title: "",
    description: "",
    isTrailer: false,
    videoUrl: null,
    poster: null,
    prompt: "",
    seed: "",
    seriesSeason: null,
    seriesEpisode: null,
    trailerProgressBar: false,
    trailerProgressCount: 0,
    selectedSeries: null,
    noOfEpisodes: 0,
    series: [],
    userVideos: [],
  },
  reducers: {
    setSteps: (state, action) => {
      state.step = action.payload;
    },
    setVideoFile: (state, action) => {
      state.title = action.payload.name;
      state.videoUrl = action.payload.url;
      state.isTrailer = action.payload.isTrailer || false;
      state.step = action.payload.step;
    },
    clearVideo: (state) => {
      state.title = "";
      state.description = "";
      state.poster = null;
      state.videoUrl = null;
      state.step = 1;
      state.prompt = "";
      state.seed = "";
      state.trailerProgressBar = false;
      state.trailerProgressCount = 0;
      state.isTrailer = false;
      state.seriesSeason = null;
      state.seriesEpisode = null;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setPoster: (state, action) => {
      state.poster = action.payload;
    },
    setSeriesSeason: (state, action) => {
      state.seriesSeason = action.payload;
    },
    setSeriesEpisode: (state, action) => {
      state.seriesEpisode = action.payload;
    },
    clearPoster: (state) => {
      state.poster = null;
    },
    addSeries: (state, action) => {
      state.series = [...state.series, action.payload];
    },
    editSeries(state, action) {
      const { id, data } = action.payload;
      state.series = state.series.map((item) =>
        item.id === id ? { ...item, ...data } : { ...item }
      );
    },
    addSelectedSeries: (state, action) => {
      state.selectedSeries = action.payload;
      const num = state.userVideos.filter(
        (e) => e.templateGroupID == action.payload
      );
      state.noOfEpisodes = num.length;
    },
    addTrailerProgressCount: (state, action) => {
      state.trailerProgressCount = action.payload;
    },
    addTrailerProgressBar: (state, action) => {
      state.trailerProgressBar = action.payload;
    },
    setPrompt: (state, action) => {
      state.prompt = action.payload;
    },
    seetSeed: (state, action) => {
      state.seed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addUSerSeries.fulfilled, (state, action) => {
      state.series = action.payload;
    });
    builder.addCase(getUserVideos.fulfilled, (state, action) => {
      state.userVideos = action.payload;
    });
  },
});

export const {
  setSteps,
  setVideoFile,
  clearVideo,
  setPoster,
  clearPoster,
  setTitle,
  editSeries,
  setDescription,
  addSeries,
  addSelectedSeries,
  addTrailerProgressCount,
  addTrailerProgressBar,
  setPrompt,
  seetSeed,
  setSeriesSeason,
  setSeriesEpisode,
} = upload.actions;
export default upload.reducer;
